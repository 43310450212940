import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import Bokhyllan from './components/Bokhyllan'

ReactDOM.render(
  <React.StrictMode>
    <Bokhyllan />
  </React.StrictMode>,
  document.getElementById('root')
)

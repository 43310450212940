import { useState } from 'react'
import classNames from 'classnames'
import './styles/Adder.sass'
import Input from './Input'
import { addBook } from './helpers'

function Adder({ firestore }) {
  const [expanded, setExpanded] = useState(false)
  const [infoText, setInfoText] = useState(null)
  const [author, setAuthor] = useState('')
  const [title, setTitle] = useState('')

  const tryAddBook = async () => {
    if (!author || !title) {
      return setInfoText('Ange både författare och titel')
    }

    setInfoText('Lägger till...')
    await addBook(firestore, author, title)
    setInfoText('Tillagd!')
    setAuthor('')
    setTitle('')
  }

  return (
    <div className={classNames('adder', { expanded: expanded })}>
      {expanded ? (
        <div className='form'>
          <div className='header'>
            <div className='title'>Ny bok</div>
            <button onClick={() => setExpanded(false)}>
              <span className='material-icons'>close</span>
            </button>
          </div>
          <div className='body'>
            <Input
              value={author}
              onTextChange={(v) => setAuthor(v)}
              placeholder={'Författare'}
            />
            <Input
              value={title}
              onTextChange={(v) => setTitle(v)}
              placeholder={'Titel'}
            />
            {infoText && (
              <div className='center'>
                <div className='info'>{infoText}</div>
              </div>
            )}
            <div className='center'>
              <button onClick={() => tryAddBook()} className='confirm'>
                Lägg till
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='expander' onClick={() => setExpanded(true)}>
          <span className='material-icons'>add</span>
        </div>
      )}
    </div>
  )
}

export default Adder

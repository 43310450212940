import classNames from 'classnames'
import './styles/Input.sass'

function Input({ icon, placeholder, value, onTextChange }) {
  return (
    <div className='input'>
      {icon && <span className='material-icons'>search</span>}
      <input
        onInput={(e) => onTextChange(e.target.value ?? '')}
        type='text'
        className={classNames({ icon: !!icon })}
        value={value ?? ''}
        placeholder={placeholder}
      />
    </div>
  )
}

export default Input

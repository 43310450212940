import { useState } from 'react'
import classNames from 'classnames'
// import { collection } from 'firebase/firestore'
// import { useCollection } from 'react-firebase-hooks/firestore'
import './styles/Books.sass'
import { deleteBook } from './helpers'

function Books({ firestore, books, filterString }) {
  const [selectedBook, setSelectedBook] = useState(null)
  const [sorting, setSorting] = useState({ value: 'author', reverse: false })

  console.log('Updated books...')

  const onClickBook = (i, book) => {
    if (i === selectedBook) {
      if (window.confirm('Vill du ta bort boken?')) {
        deleteBook(firestore, book.author, book.title)
      }
      setSelectedBook(null)
    } else {
      setSelectedBook(i)
    }
  }

  const getSortIcon = (value) => {
    if (value !== sorting.value) return 'unfold_more'
    return sorting.reverse ? 'expand_less' : 'expand_more'
  }

  const toggleSorting = (value) => {
    setSorting({
      value: value,
      reverse: value === sorting.value ? !sorting.reverse : false,
    })
  }

  const filteredBooks = books.filter(
    (book) =>
      book.author.toLowerCase().includes(filterString) ||
      book.title.toLowerCase().includes(filterString)
  )

  const sortedBooks = filteredBooks.sort((book1, book2) => {
    if (sorting.reverse) {
      ;[book2, book1] = [book1, book2]
    }
    let [str1, str2] =
      sorting.value === 'author'
        ? [book1.author, book2.author]
        : [book1.title, book2.title]

    if (str1 === str2) {
      // Sort using other value instead.
      ;[str1, str2] =
        sorting.value === 'author'
          ? [book1.title, book2.title]
          : [book1.author, book2.author]
    }

    return str1.localeCompare(str2)
  })

  return (
    <div>
      {/* {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <span>Laddar...</span>} */}
      {books && (
        <table>
          <thead>
            <tr>
              <th className='title' onClick={() => toggleSorting('author')}>
                <div className='th-title'>
                  Författare
                  <span className='material-icons'>
                    {getSortIcon('author')}
                  </span>
                </div>
              </th>
              <th className='title' onClick={() => toggleSorting('title')}>
                <div className='th-title'>
                  Titel
                  <span className='material-icons'>{getSortIcon('title')}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedBooks.map((book, i) => {
              return (
                <tr
                  key={'_book' + i}
                  className={classNames({ delete: i === selectedBook })}
                  onClick={() => onClickBook(i, book)}
                >
                  <td>{book.author}</td>
                  <td>{book.title}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Books

import { useState, useEffect } from 'react'
import { initFirestore, downloadBooks, addBook } from './helpers'
import Books from './Books'
import Input from './Input'
import Adder from './Adder'

import './styles/Bokhyllan.sass'
import { query, collection, onSnapshot } from 'firebase/firestore'

const firestore = initFirestore()
const bookQuery = query(collection(firestore, 'books'))

function Bokhyllan() {
  const [state, setState] = useState({
    modal: null,
    searchString: '',
  })
  const [books, setBooks] = useState([])

  useEffect(() => {
    const unsubscribe = onSnapshot(bookQuery, (bookSnapshot) => {
      const _books = []
      bookSnapshot.forEach((doc) => _books.push(doc.data()))
      console.log({ _books })
      setBooks(_books)
    })
    console.log({
      downloadBooks,
      uploadBooks,
    })
    return () => unsubscribe()
  }, [])

  const uploadBooks = async (books) => {
    if (!books) {
      console.log('Provide books.')
      return
    }
    console.log('Uploading books...')
    books.forEach((book) => {
      const author = book.author
      const title = book.title
      if (author && title) {
        addBook(firestore, author, title)
      }
    })
  }

  const askDownload = () => {
    if (window.confirm('Vill du ladda ner all böcker?')) {
      downloadBooks(firestore)
    }
  }

  const onSearchTextChange = (text) => {
    setState({ ...state, searchString: text })
  }

  return (
    <div className='main'>
      <div className='logo'>
        <img onClick={() => askDownload()} alt='Logotyp' src={'/logo.png'} />
      </div>
      <div className='search'>
        <Input
          placeholder={'Sök...'}
          icon={'search'}
          value={state.searchString.toLowerCase()}
          onTextChange={onSearchTextChange}
        />
      </div>
      {firestore && (
        <>
          <Books
            firestore={firestore}
            books={books}
            filterString={state.searchString}
          />
          <Adder firestore={firestore} />
        </>
      )}
    </div>
  )
}

export default Bokhyllan

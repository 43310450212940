import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  getFirestore,
} from 'firebase/firestore'

import { initializeApp } from 'firebase/app'

export const initFirestore = () => {
  const app = initializeApp({
    apiKey: 'AIzaSyAjcz-TTjaKOi1HOKPSImd-EvHMXBTP-x4',
    authDomain: 'bokhyllan-fc84d.firebaseapp.com',
    projectId: 'bokhyllan-fc84d',
    storageBucket: 'bokhyllan-fc84d.appspot.com',
    messagingSenderId: '1076267192616',
    appId: '1:1076267192616:web:bea5425e0f777f61d8401d',
  })

  return getFirestore(app)
}

export const downloadString = (value) => {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(value)
  )
  element.setAttribute('download', 'books.json')
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const getBooks = async (firestore) => {
  const booksCollection = collection(firestore, 'books')
  const booksSnapshot = await getDocs(booksCollection)
  const booksList = booksSnapshot.docs.map((doc) => doc.data())
  console.log('Got books', booksList)
  return booksList
}

export const getBookRef = (firestore, author, title) => {
  const bookId = author + title
  const bookRef = doc(firestore, 'books', bookId)
  return bookRef
}

export const addBook = async (firestore, author, title) => {
  console.log('Adding book')
  await setDoc(getBookRef(firestore, author, title), {
    author: author,
    title: title,
  })
}

export const downloadBooks = async (firestore) => {
  const books = await getBooks(firestore)
  downloadString(JSON.stringify(books))
}

export const updateBook = async (
  firestore,
  author,
  title,
  newAuthor,
  newTitle
) => {
  console.log('Updating book')
  await updateDoc(getBookRef(firestore, author, title), {
    author: newAuthor,
    title: newTitle,
  })
}

export const deleteBook = async (firestore, author, title) => {
  console.log('Deleting book')
  await deleteDoc(getBookRef(firestore, author, title))
}
